import React from 'react';
import { LogoSVG } from 'assets/icons/logo';
import { LinkSVG } from 'assets/icons/link';
import { AnimatedDivPage } from 'components/animatedPage';
import { RouterLink } from 'router/components/routerLink';
import s from './index.module.scss';
import { ErrorPageProps } from './types';

export const ErrorPage = (props: ErrorPageProps) => {
  const { status, text } = props;

  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatedDivPage className={s.error} transition={{ duration: 0.5 }} animations={animations}>
      <div className={s.error__logo}>
        <LogoSVG />
      </div>

      <h1 className={s.error__title}>
        {' '}
        {Number(status) ? `Error page ${status}` : `Page ${status}`}
      </h1>

      <div className={s.error__description}>
        <p>{text}</p>
      </div>

      <div className={s.error__back}>
        <RouterLink to={-1}>
          <p>Back to previous page</p>
        </RouterLink>
        <div className={s.icon}>
          <LinkSVG />
        </div>
      </div>
    </AnimatedDivPage>
  );
};
