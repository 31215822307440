export enum AppPaths {
  main = '/',
  signin = 'signin',
  signup = 'signup',
  signupSuccess = 'signup-success',
  signinSuccess = 'signin-success',
  forgotPassword = 'password-forgot',
  changePassword = 'password-change',
  successPassword = 'password-success',
  employeesList = 'employees',
  employeesDetail = 'employees-detail/:id/',
  createEmployee = 'create-employee',
  paymentHistory = 'history',
  groups = 'groups',
  moreFunctions = 'functions',
  moreFunctionsDetail = 'functions-detail/import',
  createGroup = 'create-group',
  groupsDetail = 'groups-detail/:id/',
  paymentHistoryDetail = 'history-detail/:id/',
  payment = 'payment',
  paymentСonfirmation = 'payment-confirmation',
  profile = 'profile',
  NotFound = '*',
  NotFound404 = '404',
  InternalError500 = '500',
  Error502 = '502',
  Error504 = '504',
  Error403 = '403',
}

export const AppPrivatePath = ['/profile', '/employees', '/employees/employees-detail/:id/', '/history', '/history/history-detail/:id/', '/payment', '/employees/create-employee'];
export const authPaths: string[] = ['signin', 'signup', 'signin-success', 'signup-success', 'password-forgot', 'password-change', 'password-success'];